<template>
  <div class="xpp-toolbar-box" :class="svgToolbar">
    <div class="xpp-toolbar">
      <div class="drawing-color">
        <div
          class="drawing-color-option"
          v-for="color in colorArr"
          :key="color"
        >
          <div
            :class="{ active: color === colorActive }"
            :style="{ backgroundColor: color }"
            @click="strokeChange(color)"
          ></div>
        </div>
        <div class="drawing-color-option">
          <div class="path-color-picker">
            <el-color-picker
              v-model="pathColor"
              @change="colorPicker"
            ></el-color-picker>
            <span
              class="color-picker-active"
              v-if="pathColor"
              :style="{ backgroundColor: pathColor }"
            ></span>
          </div>
        </div>
      </div>
      <div class="toolbar-divider"></div>
      <div class="drawing-sliders">
        <div class="each-slider-box">
          <input
            type="range"
            name="strokeWeight"
            class="drawing-slider-input"
            min="1"
            max="30"
            v-model="strokeInfo.weight"
            @change="strokeWeightChange"
          />
        </div>
      </div>
      <div class="toolbar-divider"></div>
      <!--   橡皮   -->
      <div class="drawing-rubber-box">
        <div
          class="drawing-rubber-btn iconfont icon-eraser"
          :class="{ 'drawing-avtive': activeRemovePath }"
          @click="activeRmovePath"
        ></div>
      </div>
      <div class="toolbar-divider"></div>
      <!--   删除所有   -->
      <div class="drawing-delete-box">
        <div class="drawing-delete-btn" @click="delAllSvgPath">
          删除所有笔迹
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "SvgToolbar",
  computed: {
    svgToolbar() {
      return {
        stretch: this.slideSidebarOpen,
        active: this.editToolbarActive === 2
      };
    },
    colorActive() {
      return this.strokeInfo.color;
    },
    ...mapGetters([
      "paperGroupId",
      "paperViewAll",
      "paperPageInfo",
      "strokeInfo",
      "editToolbarActive",
      "slideSidebarOpen",
      "activeRemovePath"
    ])
  },
  data() {
    return {
      strokeWeight: 1,
      pathColor: null,
      readyDelPath: [],
      readyDelPathID: {},
      colorArr: [
        "#ff6565",
        "#ff78ed",
        "#F7BC03",
        "#4090f3",
        "#4aedff",
        "#51e37f",
        "#ffd342",
        "#ffaa55"
      ]
    };
  },
  mounted() {
    if (this.strokeInfo.custom) {
      this.pathColor = this.strokeInfo.color;
    }
  },
  methods: {
    strokeChange(color) {
      this.pathColor = null;
      this.$UpdataVuexState({
        key: "strokeInfo",
        data: { color },
        local: true
      });
    },
    colorPicker(color) {
      this.$UpdataVuexState({
        key: "strokeInfo",
        data: { color, custom: true },
        local: true
      });
    },
    strokeWeightChange() {
      this.$UpdataVuexState({
        key: "strokeInfo",
        data: { weight: this.strokeInfo.weight },
        local: true
      });
    },
    activeRmovePath() {
      let data = !this.activeRemovePath;
      this.$UpdataVuexState({
        key: "activeRemovePath",
        data
      });
      if (data) {
        document.addEventListener("mousedown", this.viewRmovePathEventBind);
        document.addEventListener("mouseup", this.viewRmovePathEventBind);
      } else {
        document.removeEventListener("mousedown", this.viewRmovePathEventBind);
        document.removeEventListener("mouseup", this.viewRmovePathEventBind);
      }
    },
    viewRmovePathEventBind(event) {
      switch (event.type) {
        case "mousedown":
          document.addEventListener("mousemove", this.viewMousemove);
          break;
        case "mouseup":
          document.removeEventListener("mousemove", this.viewMousemove);
          this.readyDelPath.length && this.$emit("back", this.readyDelPath);
          this.readyDelPath = [];
          this.readyDelPathID = {};
          break;
      }
    },
    viewMousemove({ target }) {
      if (target.tagName !== "path" || !this.activeRemovePath) return;
      let dom = target.parentElement.parentElement;
      dom.style.opacity = 0.2;
      if (this.readyDelPathID[dom.id]) return;
      this.readyDelPathID[dom.id] = true;
      this.readyDelPath.push(dom);
    },
    delAllSvgPath() {
      let idx = document.getElementById(this.paperPageInfo.page_id).dataset
        .index;

      this.$WsSendMsg({
        action: "DELELEMENT",
        type: "svgSequence",
        data: {
          _id: this.paperPageInfo.page_id, //page_id
          type: "svgSequence",
          index: idx
        }
      });
      this.$DelPageElement({
        index: idx,
        type: "svgSequence"
      });
    },
    ...mapMutations(["$UpdataVuexState", "$DelPageElement"]),
    ...mapActions(["$WsSendMsg"])
  }
};
</script>

<style lang="scss" scoped>
.drawing-color {
  display: flex;
  align-items: center;

  .drawing-color-option {
    flex: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      cursor: pointer;
      flex: none;
      width: 14px;
      height: 14px;
      border-radius: 100%;
      box-shadow: 0 0 0 1px #fff;
      transition: transform 0.2s;

      &:hover,
      &.active {
        box-shadow: 0 0 0 2px #fff;
        transform: scale(1.1);
      }
    }
  }
}

.drawing-sliders {
  display: flex;
  align-items: center;
  justify-content: center;

  .each-slider-box {
    padding: 8px 10px;
    display: flex;
    align-items: center;
  }

  .drawing-slider-input {
    flex: none;
    width: 130px;
    cursor: pointer;
    padding: 0;
    background-color: transparent !important;
    border: 0 !important;
    &:focus,
    &:hover {
      background-color: transparent !important;
      border: 0 !important;
    }
  }
}

.drawing-slider-input::-webkit-slider-runnable-track {
  background: #e8ebef;
  height: 2px;
  outline: 0;
}

.drawing-slider-input::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  cursor: pointer;
  border: 2px solid #373839;
  background-color: #fff;
}

.drawing-rubber-box {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawing-rubber-btn {
  flex: none;
  font-size: 16px;
  cursor: pointer;
  color: #8d92a1;
  &:hover {
    color: #fff;
  }
  &.drawing-avtive {
    color: #fff;
  }
}

.drawing-delete-btn {
  color: #8d92a1;
  font-size: 12px;
  padding: 0 20px;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
}

.el-color-picker {
  height: 100%;
  width: 100%;
  opacity: 0;
}

.path-color-picker {
  position: relative;
  background: url(/img/color_picker.png) no-repeat center/cover;
  .color-picker-active {
    position: absolute;
    width: 6px;
    height: 6px;
    border: 2px solid #fff;
    border-radius: 100%;
    right: -4px;
    bottom: -4px;
    pointer-events: none;
  }
}
::v-deep {
  .el-color-picker__trigger {
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
